body {
  --gs-red: #cc0000;
  --green: #13bd0f;
  margin: 0;
  padding: 0;
  font-size: 10px;
  height: 100vh;
  font-family: "Raleway", sans-serif;
}
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}
